import React from 'react'
import { Link } from 'react-router-dom'
import withBreadcrumbs from 'react-router-breadcrumbs-hoc'
import { injectIntl } from 'react-intl'
import { Skeleton } from 'antd'
import { connect } from 'react-redux'
import dayjs from 'dayjs'

// import _ from 'lodash';
// import setupRoutes from 'containers/Setup/routes';
import financeBankRoutes from 'containers/FinanceBanks/routes'
import { getTerm } from 'utils/hooks/useTerm'
import routeConfig from '../../../routes'
import styles from './style.module.scss'

const mapStateToProps = ({
  inits: { loadedInit },
  billing,
  menu,
  options: { options },
  settings,
  finance: {
    warehouse: { warehouses },
    bankAccounts,
    paymentConnectProviders,
  },
  translation,
}) => ({
  billing,
  menuLeftData: menu.menuLeftData,
  isMobileView: settings.isMobileView,
  darkMode: options.dark_mode,
  warehouses,
  accounts: bankAccounts,
  translation,
  loadedInit,
  paymentConnectProviders,
})

@connect(mapStateToProps)
class Breadcrumbs extends React.PureComponent {
  getWarehouseName = (id) => {
    const { warehouses } = this.props
    let name = ''
    if (id) {
      const findWarehouse = warehouses.find((row) => row.id === id)
      name = findWarehouse ? findWarehouse.name : ''
    }

    return name
  }

  getAccountName = (id) => {
    const { accounts } = this.props
    let name = ''
    if (id) {
      const findAccount = accounts.find((row) => row.id === id)
      name = findAccount ? findAccount.name : ''
    }

    return name
  }

  render() {
    const {
      loadedInit,
      breadcrumbs,
      menuLeftData,
      isMobileView,
      intl,
      billing,
      paymentConnectProviders,
      darkMode,
      translation: { menu = {}, reportmenu = {}, term = {} },
    } = this.props

    if (isMobileView) {
      return <div />
    }

    const _term = getTerm({ formatMessage: intl.formatMessage, term })

    const countBreadCrumb = breadcrumbs.length
    const arrMenuLeft = []
    ;(menuLeftData || []).forEach((data) => {
      if (data.children) {
        ;(data.children || []).forEach((chil) => arrMenuLeft.push(chil))
      }
      const newData = { ...data }
      delete newData.children
      arrMenuLeft.push(newData)
    })

    return (
      <div
        className={styles.breadcrumbs}
        style={{
          backgroundColor: darkMode ? '#141414' : '#FFFFFF',
          borderBottom: darkMode ? '1px solid #141414' : '1px solid #e4e9f0',
        }}
      >
        {!loadedInit ? (
          <Skeleton.Input style={{ width: 300 }} active />
        ) : (
          <div className={styles.path} style={{ color: darkMode ? '#FFFFFF' : '#141414' }}>
            {breadcrumbs.map((bread, index) => {
              const { match, breadcrumb } = bread
              const findRoute = arrMenuLeft.find((v) => v.url === match.url)
              let link = null

              if (index === breadcrumbs.length - 1) {
                link = <strong>{breadcrumb}</strong>
              }
              if (typeof breadcrumb.type === 'object') {
                link = breadcrumb
              }

              if (findRoute) {
                if (countBreadCrumb > 1 && index !== breadcrumbs.length - 1) {
                  link = (
                    <Link key={match.url} to={match.url} className="text-muted">
                      {findRoute.title}
                    </Link>
                  )
                } else {
                  link = <strong>{findRoute.title}</strong>
                }
              }

              if (match.url === '/sales') {
                link = (
                  <Link key={match.url} to={`${match.url}/overview`} className="text-muted">
                    {menu.sales}
                  </Link>
                )
              }
              if (match.url === '/sales/invoices') {
                if (index === breadcrumbs.length - 1) {
                  link = <strong>{menu.invoices}</strong>
                } else {
                  link = (
                    <Link key={match.url} to={match.url} className="text-muted">
                      {menu.invoices}
                    </Link>
                  )
                }
              }
              if (match.url === '/sales/quotes') {
                if (index === breadcrumbs.length - 1) {
                  link = <strong>{menu.quotes}</strong>
                } else {
                  link = (
                    <Link key={match.url} to={match.url} className="text-muted">
                      {menu.quotes}
                    </Link>
                  )
                }
              }
              if (match.url === '/sales/orders') {
                if (index === breadcrumbs.length - 1) {
                  link = <strong>{menu.orders}</strong>
                } else {
                  link = (
                    <Link key={match.url} to={match.url} className="text-muted">
                      {menu.orders}
                    </Link>
                  )
                }
              }
              if (match.url === '/sales/deliveries') {
                if (index === breadcrumbs.length - 1) {
                  link = <strong>{menu.deliveries}</strong>
                } else {
                  link = (
                    <Link key={match.url} to={match.url} className="text-muted">
                      {menu.deliveries}
                    </Link>
                  )
                }
              }
              if (match.url === '/sales/overview') {
                link = <strong>{menu.overview}</strong>
              }
              if (match.url === '/sales/returns') {
                link = (
                  <Link key={match.url} to="/sales/invoices?status_id=retur" className="text-muted">
                    {menu.returns}
                  </Link>
                )
              }

              if (match.url === '/purchases') {
                link = (
                  <Link key={match.url} to={`${match.url}/overview`} className="text-muted">
                    {menu.purchases}
                  </Link>
                )
              }
              if (match.url === '/purchases/purchaseinvoices') {
                if (index === breadcrumbs.length - 1) {
                  link = <strong>{menu.purchase_invoices}</strong>
                } else {
                  link = (
                    <Link key={match.url} to={match.url} className="text-muted">
                      {menu.purchase_invoices}
                    </Link>
                  )
                }
              }
              if (match.url === '/purchases/purchaseorders') {
                if (index === breadcrumbs.length - 1) {
                  link = <strong>{menu.purchase_orders}</strong>
                } else {
                  link = (
                    <Link key={match.url} to={match.url} className="text-muted">
                      {menu.purchase_orders}
                    </Link>
                  )
                }
              }
              if (match.url === '/purchases/quotes') {
                if (index === breadcrumbs.length - 1) {
                  link = <strong>{menu.purchase_quotes}</strong>
                } else {
                  link = (
                    <Link key={match.url} to={match.url} className="text-muted">
                      {menu.purchase_quotes}
                    </Link>
                  )
                }
              }
              if (match.url === '/purchases/deliveries') {
                if (index === breadcrumbs.length - 1) {
                  link = <strong>{menu.purchase_deliveries}</strong>
                } else {
                  link = (
                    <Link key={match.url} to={match.url} className="text-muted">
                      {menu.purchase_deliveries}
                    </Link>
                  )
                }
              }
              if (match.url === '/purchases/overview') {
                link = <strong>{menu.overview}</strong>
              }
              if (match.url === '/purchases/returns') {
                link = (
                  <Link
                    key={match.url}
                    to="/purchases/purchaseinvoices?status_id=retur"
                    className="text-muted"
                  >
                    {menu.purchase_returns}
                  </Link>
                )
              }

              if (match.url === '/pos') {
                link = (
                  <span key={match.url} className="text-muted">
                    {menu.pos}
                  </span>
                )
              }
              if (match.url === '/pos/product-favorites') {
                link = <strong>{menu.pos_product_favorites}</strong>
              }
              if (match.url === '/pos/users') link = <strong>{menu.pos_cashier_users}</strong>
              if (match.url === '/pos/outlets') link = <strong>{menu.pos_outlets}</strong>

              if (match.url === '/accounts/conversionbalances/conversiondate') {
                link = <strong>{intl.formatMessage({ id: 'breadcrumb.conversiondate' })}</strong>
              }
              if (match.url === '/accounts/conversionbalances') {
                if (index === breadcrumbs.length - 1) {
                  link = (
                    <strong>{intl.formatMessage({ id: 'breadcrumb.conversionbalances' })}</strong>
                  )
                } else {
                  link = (
                    <Link key={match.url} to={match.url} className="text-muted">
                      {intl.formatMessage({ id: 'breadcrumb.conversionbalances' })}
                    </Link>
                  )
                }
              }

              if (match.url === '/dashboard') {
                link = <strong>{menu.home}</strong>
              }

              if (match.url === '/billing/upgrade') {
                link = (
                  <strong>
                    {billing.expiry_date &&
                    dayjs().subtract(1, 'days').isAfter(billing.expiry_date) &&
                    billing.can_be_renewed
                      ? intl.formatMessage({ id: 'billing.renew' })
                      : intl.formatMessage({ id: 'billing.upgrade' })}
                  </strong>
                )
              }
              if (match.url === '/billing') {
                if (index === breadcrumbs.length - 1) {
                  link = <strong>{menu.billing}</strong>
                } else {
                  link = (
                    <Link key={match.url} to={match.url} className="text-muted">
                      {menu.billing}
                    </Link>
                  )
                }
              }

              if (match.url === '/reports') {
                if (index === breadcrumbs.length - 1) {
                  link = <strong>{menu.reports}</strong>
                } else {
                  link = (
                    <Link key={match.url} to={match.url} className="text-muted">
                      {menu.reports}
                    </Link>
                  )
                }
              }

              if (match.url === '/companies') {
                if (index === breadcrumbs.length - 1) {
                  link = <strong>{intl.formatMessage({ id: 'companies.company_list' })}</strong>
                } else {
                  link = (
                    <Link key={match.url} to={match.url} className="text-muted">
                      {intl.formatMessage({ id: 'companies.company_list' })}
                    </Link>
                  )
                }
              }

              if (match.url === '/settings') {
                if (index === breadcrumbs.length - 1) {
                  link = <strong>{menu.settings}</strong>
                } else {
                  link = (
                    <Link key={match.url} to={match.url} className="text-muted">
                      {menu.settings}
                    </Link>
                  )
                }
              }
              if (match.url === '/settings/account-mapping') {
                if (index === breadcrumbs.length - 1) {
                  link = (
                    <strong>{intl.formatMessage({ id: 'accountMapping.account_mappinng' })}</strong>
                  )
                } else {
                  link = (
                    <Link key={match.url} to={match.url} className="text-muted">
                      {intl.formatMessage({ id: 'accountMapping.account_mappinng' })}
                    </Link>
                  )
                }
              }
              if (match.url === '/settings/apps') {
                if (index === breadcrumbs.length - 1) {
                  link = <strong>{intl.formatMessage({ id: 'appSetting.apps' })}</strong>
                } else {
                  link = (
                    <Link key={match.url} to={match.url} className="text-muted">
                      {intl.formatMessage({ id: 'appSetting.apps' })}
                    </Link>
                  )
                }
              }
              if (match.url === '/settings/multi-currency') {
                if (index === breadcrumbs.length - 1) {
                  link = (
                    <strong>{intl.formatMessage({ id: 'multiCurrency.multi_currency' })}</strong>
                  )
                } else {
                  link = (
                    <Link key={match.url} to={match.url} className="text-muted">
                      {intl.formatMessage({ id: 'multiCurrency.multi_currency' })}
                    </Link>
                  )
                }
              }
              if (match.url === '/settings/marketplaceConnect') {
                if (index === breadcrumbs.length - 1) {
                  link = <strong>Marketplace Connect</strong>
                } else {
                  link = (
                    <Link key={match.url} to={match.url} className="text-muted">
                      Marketplace Connect
                    </Link>
                  )
                }
              }
              if (match.url === '/settings/sales-commissions') {
                if (index === breadcrumbs.length - 1) {
                  link = (
                    <strong>
                      {intl.formatMessage({ id: 'salesCommission.sales_commission' })}
                    </strong>
                  )
                } else {
                  link = (
                    <Link key={match.url} to={match.url} className="text-muted">
                      {intl.formatMessage({ id: 'salesCommission.sales_commission' })}
                    </Link>
                  )
                }
              }

              if (match.url === '/reports/sales-per-period') {
                link = <strong>{reportmenu.sales_per_period}</strong>
              }
              if (match.url === '/reports/purchases-per-period') {
                link = <strong>{reportmenu.purchases_per_period}</strong>
              }
              if (match.url === '/reports/receivable-payable-per-contact') {
                link = <strong>{reportmenu.payable_credit_per_contact}</strong>
              }
              if (match.url === '/accounts/conversionbalances/opening_balance') {
                link = (
                  <strong>{intl.formatMessage({ id: 'financeConversion.opening_balance' })}</strong>
                )
              }
              if (match.url === '/accounts/conversionbalances/reversal_detail') {
                link = (
                  <strong>{intl.formatMessage({ id: 'financeConversion.reversal_detail' })}</strong>
                )
              }
              if (match.url === '/reports/inventory-summary') {
                link = <strong>{reportmenu.inventory_summary}</strong>
              }
              if (match.url === '/reports/inventory-stock-movement') {
                link = <strong>{reportmenu.inventory_stock_movement}</strong>
              }
              if (match.url === '/reports/warehouse-stock-summary') {
                link = <strong>{reportmenu.warehouse_stock_summary}</strong>
              }
              if (match.url === '/reports/warehouse-stock-movement') {
                link = <strong>{reportmenu.warehouse_stock_movement}</strong>
              }
              if (match.url === '/reports/inventory-stock-adjustment') {
                link = <strong>{reportmenu.report_inventory_stock_adjustment}</strong>
              }
              if (match.url === '/reports/warehouse-transfer') {
                link = <strong>{reportmenu.inventory_warehouse_transfer}</strong>
              }
              if (match.url === '/reports/production') {
                link = <strong>{reportmenu.production}</strong>
              }
              if (match.url === '/reports/shipping-cost-per-expedition') {
                link = <strong>{reportmenu.shipping_cost_per_expedition}</strong>
              }
              if (match.url === '/reports/sales-per-sales-person') {
                link = <strong>{reportmenu.sales_per_sales_person}</strong>
              }
              if (match.url === '/reports/product-profitability') {
                link = <strong>{reportmenu.profitability_product}</strong>
              }
              if (match.url === '/reports/profitability-per-invoice') {
                link = <strong>{reportmenu.profitability_per_invoice}</strong>
              }
              if (match.url === '/reports/invoice-payment') {
                link = <strong>{reportmenu.invoice_payment}</strong>
              }
              if (match.url === '/reports/product-sales-per-customer') {
                link = <strong>{reportmenu?.product_sales_per_customer}</strong>
              }
              if (match.url === '/reports/sales-per-product-category') {
                link = <strong>{reportmenu.sales_per_product_category}</strong>
              }
              if (match.url === '/reports/product-purchases-per-vendor') {
                link = <strong>{reportmenu?.product_purchases_per_supplier}</strong>
              }
              if (match.url === '/reports/purchase-invoice-payment') {
                link = <strong>{reportmenu.purchases_invoice_payment}</strong>
              }
              if (match.url === '/reports/general-ledger') {
                if (index === breadcrumbs.length - 1) {
                  link = <strong>{reportmenu.general_ledger}</strong>
                } else {
                  link = (
                    <Link key={match.url} to={match.url} className="text-muted">
                      {reportmenu.general_ledger}
                    </Link>
                  )
                }
              }
              if (match.url === '/reports/general-ledger/category') {
                if (index === breadcrumbs.length - 1) {
                  link = <strong>{intl.formatMessage({ id: 'report.category' })}</strong>
                } else {
                  link = (
                    <Link key={match.url} to={match.url} className="text-muted">
                      {intl.formatMessage({ id: 'report.category' })}
                    </Link>
                  )
                }
              }
              if (match.url === '/reports/journal') {
                link = <strong>{reportmenu.journal}</strong>
              }
              if (match.url === '/reports/bank-summary') {
                link = <strong>{reportmenu.bank_summary}</strong>
              }
              if (match.url === '/reports/sales-tax') {
                link = <strong>{reportmenu.sales_tax}</strong>
              }
              if (match.url === '/reports/witholding-tax') {
                link = <strong>{reportmenu.witholding_tax}</strong>
              }
              if (match.url === '/reports/customer-invoice') {
                link = <strong>{reportmenu.customer_invoice}</strong>
              }
              if (match.url === '/reports/supplier-invoice') {
                link = <strong>{reportmenu.supplier_invoice}</strong>
              }
              if (match.url === '/reports/sales-detail') {
                link = <strong>{reportmenu.sales_detail}</strong>
              }
              if (match.url === '/reports/purchase-detail') {
                link = <strong>{reportmenu.purchase_detail}</strong>
              }
              if (match.url === '/reports/aged-payable') {
                if (index === breadcrumbs.length - 1) {
                  link = <strong>{reportmenu.aged_payables}</strong>
                } else {
                  link = (
                    <Link key={match.url} to={match.url} className="text-muted">
                      {reportmenu.aged_payables}
                    </Link>
                  )
                }
              }
              if (match.url === '/reports/aged-receivable') {
                if (index === breadcrumbs.length - 1) {
                  link = <strong>{reportmenu.aged_receivables}</strong>
                } else {
                  link = (
                    <Link key={match.url} to={match.url} className="text-muted">
                      {reportmenu.aged_receivables}
                    </Link>
                  )
                }
              }
              if (match.url === '/reports/expense-claim') {
                if (index === breadcrumbs.length - 1) {
                  link = <strong>{reportmenu.expense_claim_detail}</strong>
                } else {
                  link = (
                    <Link key={match.url} to={match.url} className="text-muted">
                      {reportmenu.expense_claim_detail}
                    </Link>
                  )
                }
              }

              if (match.url === '/reports/import') {
                if (index === breadcrumbs.length - 1) {
                  link = <strong>{intl.formatMessage({ id: 'button.import' })}</strong>
                } else {
                  link = (
                    <Link key={match.url} to={match.url} className="text-muted">
                      {intl.formatMessage({ id: 'button.import' })}
                    </Link>
                  )
                }
              }
              if (match.url === '/reports/team-activities') {
                link = <strong>{reportmenu.activity_team}</strong>
              }

              if (match.url === '/reports/duty-stamp') {
                link = <strong>{reportmenu.duty_stamp}</strong>
              }

              if (match.url === '/sales/invoices/join-invoice') {
                link = <strong>{intl.formatMessage({ id: 'financeInvoices.join_invoice' })}</strong>
              }

              if (match.url === '/purchases/purchaseinvoices/join-invoice') {
                link = <strong>{intl.formatMessage({ id: 'financeInvoices.join_purchase_invoice' })}</strong>
              }

              if (match.url === '/settings/billing/stamp') {
                link = <strong>{intl.formatMessage({ id: 'stamp.billing_title' })}</strong>
              }

              if (
                match.url === '/sales/invoices/recurring' ||
                match.url === '/sales/orders/recurring' ||
                match.url === '/purchases/purchaseinvoices/recurring' ||
                match.url === '/purchases/purchaseorders/recurring' ||
                match.url === '/expenses/recurring' ||
                match.url === '/manual-journal/recurring' ||
                /banks\/[0-9]+\/recurring$/.test(match.url) ||
                /banks\/detail\/[0-9]+\/recurring$/.test(match.url)
              ) {
                if (index === breadcrumbs.length - 1) {
                  link = <strong>{intl.formatMessage({ id: 'recurring.recurring' })}</strong>
                } else {
                  link = (
                    <span className="text-muted">
                      {intl.formatMessage({ id: 'recurring.recurring' })}
                    </span>
                  )
                }
              }

              if (match.url === '/reports/income-per-customer') {
                link = <strong>{reportmenu.income_per_customer}</strong>
              }
              if (match.url === '/reports/trial-balance') {
                link = <strong>{reportmenu.trial_balance}</strong>
              }
              if (match.url === '/reports/profit-loss') {
                link = <strong>{reportmenu.profit_and_loss}</strong>
              }
              if (match.url === '/reports/expense-per-contact') {
                link = <strong>{reportmenu.expense_per_contact}</strong>
              }
              if (match.url === '/reports/sales-per-product') {
                link = <strong>{reportmenu.sales_per_product}</strong>
              }
              if (match.url === '/reports/order-per-product') {
                link = <strong>{reportmenu.order_per_product}</strong>
              }
              if (match.url === '/reports/purchases-per-vendor') {
                link = <strong>{reportmenu.purchases_per_supplier}</strong>
              }
              if (match.url === '/reports/purchases-per-product') {
                link = <strong>{reportmenu.purchases_per_product}</strong>
              }
              if (match.url === '/reports/cash-flow') {
                link = <strong>{reportmenu.cash_flow}</strong>
              }
              if (match.url === '/reports/balance-sheet') {
                link = <strong>{reportmenu.balance_sheet}</strong>
              }
              if (match.url === '/reports/equity-movement') {
                link = <strong>{reportmenu.equity_movement}</strong>
              }
              if (match.url === '/reports/executive-summary') {
                link = <strong>{reportmenu.executive_summary}</strong>
              }
              if (match.url === '/reports/account-transaction') {
                link = (
                  <strong>
                    {intl.formatMessage(
                      { id: 'report.account_transactions_title' },
                      { account: '' },
                    )}
                  </strong>
                )
              }
              if (match.url === '/reports/fixed-asset-detail') {
                link = <strong>{reportmenu.fixed_asset_detail}</strong>
              }
              if (match.url === '/reports/fixed-asset-summary') {
                link = <strong>{reportmenu.fixed_asset_summary}</strong>
              }
              if (match.url === '/reports/fixed-asset-disposal') {
                link = <strong>{reportmenu.fixed_asset_disposal}</strong>
              }
              if (match.url === '/reports/consolidation-balance-sheet') {
                link = <strong>{`${reportmenu.balance_sheet} ${menu.consolidation}`}</strong>
              }
              if (match.url === '/reports/consolidation-cash-flow') {
                link = <strong>{`${reportmenu.cash_flow} ${menu.consolidation}`}</strong>
              }
              if (match.url === '/reports/consolidation-profit-loss') {
                link = <strong>{`${reportmenu.profit_and_loss} ${menu.consolidation}`}</strong>
              }
              if (match.url === '/reports/consolidation-worksheet') {
                link = <strong>{`${reportmenu.worksheet} ${menu.consolidation}`}</strong>
              }
              if (match.url === '/reports/sales-delivery') {
                link = <strong>{reportmenu.sales_delivery}</strong>
              }
              if (match.url === '/reports/purchase-delivery') {
                link = <strong>{reportmenu.purchase_delivery}</strong>
              }
              if (match.url === '/reports/budget-management') {
                if (index === breadcrumbs.length - 1) {
                  link = <strong>{reportmenu.budget}</strong>
                } else {
                  link = (
                    <Link key={match.url} to={match.url} className="text-muted">
                      {reportmenu.budget}
                    </Link>
                  )
                }
              }
              if (match.url === '/reports/budget-profit-loss') {
                link = <strong>{reportmenu.budget_profit_loss}</strong>
              }
              if (match.url === '/manual-journal') {
                if (index === breadcrumbs.length - 1) {
                  link = (
                    <strong>
                      {intl.formatMessage({ id: 'financeManualJournal.financeManualJournal' })}
                    </strong>
                  )
                } else {
                  link = (
                    <Link key={match.url} to={match.url} className="text-muted">
                      {intl.formatMessage({ id: 'financeManualJournal.financeManualJournal' })}
                    </Link>
                  )
                }
              }
              if (match.url === '/closing') {
                if (index === breadcrumbs.length - 1) {
                  link = <strong>{intl.formatMessage({ id: 'financeClosing.closing' })}</strong>
                } else {
                  link = (
                    <Link key={match.url} to={match.url} className="text-muted">
                      {intl.formatMessage({ id: 'financeClosing.closing' })}
                    </Link>
                  )
                }
              }
              if (match.url === '/fixed-assets/transaction') {
                link = (
                  <strong>{intl.formatMessage({ id: 'financeFixedAssets.transactions' })}</strong>
                )
              }
              if (match.url === '/fixed-assets/run-depreciation') {
                link = (
                  <strong>
                    {intl.formatMessage({ id: 'financeFixedAssets.run_depreciation' })}
                  </strong>
                )
              }

              if (match.url === '/layout-invoice') {
                link = <strong>{menu.layout_invoice}</strong>
              }

              if (match.url === '/settings/layout-report') {
                link = <strong>{intl.formatMessage({ id: 'layoutReport.layout_report' })}</strong>
              }

              if (match.url === '/settings/backup-database') {
                link = <strong>{menu.finance_backup_database}</strong>
              }

              const splitUrl = match.url.split('/')
              const lastUrl = splitUrl[splitUrl.length - 1]

              // Bank custom breadcrumbs
              if (/banks\/[0-9]+$/.test(match.url) || /banks\/detail\/[0-9]+$/.test(match.url)) {
                const id = parseInt(lastUrl, 10)
                link = (
                  <Link key={match.url} to={`/banks/detail/${id}`} className="text-muted">
                    {this.getAccountName(id)}
                  </Link>
                )
              }

              if (/banks\/[0-9]+\/spend$/.test(match.url)) {
                link = <strong>{intl.formatMessage({ id: 'financeBanks.spend_money' })}</strong>
              }

              if (
                /banks\/[0-9]+\/spend\/[0-9]+$/.test(match.url) ||
                /banks\/[0-9]+\/receive\/[0-9]+$/.test(match.url)
              ) {
                link = null
              }

              if (/banks\/[0-9]+\/receive$/.test(match.url)) {
                link = <strong>{intl.formatMessage({ id: 'financeBanks.receive_money' })}</strong>
              }

              if (/banks\/transaction$/.test(match.url)) {
                if (this.props.account.id) {
                  link = <span className="text-muted">{this.props.account.name}</span>
                }
              }

              if (/banks\/detail\/[0-9]+\/transactions$/.test(match.url)) {
                link = <strong>{intl.formatMessage({ id: 'financeBanks.transactions' })}</strong>
              }

              if (match.url === '/banks') {
                if (index === breadcrumbs.length - 1) {
                  link = <strong>{menu.cash_bank}</strong>
                } else {
                  link = (
                    <Link key={match.url} to={match.url} className="text-muted">
                      {menu.cash_bank}
                    </Link>
                  )
                }
              }

              if (match.url === '/banks/connect') {
                if (index === breadcrumbs.length - 1) {
                  link = <strong>{intl.formatMessage({ id: 'financeBanks.bank_connect' })}</strong>
                } else {
                  link = (
                    <Link key={match.url} to={match.url} className="text-muted">
                      {intl.formatMessage({ id: 'financeBanks.bank_connect' })}
                    </Link>
                  )
                }
              }

              // Warehouse Custom Breadcrumbs
              if (/warehouses\/[0-9]+$/.test(match.url)) {
                const id = parseInt(lastUrl, 10)
                link = (
                  <Link key={match.url} to={`/warehouses/detail/${id}`} className="text-muted">
                    {this.getWarehouseName(id)}
                  </Link>
                )
              }

              if (match.url === '/warehouses/access' || /banks\/[0-9]+\/access$/.test(match.url)) {
                link = (
                  <span className="text-muted">
                    {intl.formatMessage({ id: 'financeWareHouses.access' })}
                  </span>
                )
              }

              if (/warehouses\/[0-9]+\/transfer$/.test(match.url)) {
                link = (
                  <span className="text-muted">
                    {intl.formatMessage({ id: 'financeWareHouses.transfer' })}
                  </span>
                )
              }

              if (/warehouses\/[0-9]+\/stock-adjustment$/.test(match.url)) {
                link = (
                  <span className="text-muted">
                    {intl.formatMessage({ id: 'financeWareHouses.stock_adjustment' })}
                  </span>
                )
              }

              if (
                /warehouses\/[0-9]+\/transfer\/detail\/[0-9]+$/.test(match.url) ||
                /warehouses\/[0-9]+\/stock-adjustment\/detail\/[0-9]+$/.test(match.url)
              ) {
                link = <strong>{intl.formatMessage({ id: 'breadcrumb.detail' })}</strong>
              }

              if (lastUrl === 'import-recurring') {
                link = (
                  <strong>
                    {intl.formatMessage({ id: 'button.import' })}{' '}
                    {intl.formatMessage({
                      id: 'recurring.repeat',
                    })}
                  </strong>
                )
              }

              if (lastUrl === 'import-return') {
                link = (
                  <strong>
                    {intl.formatMessage({
                      id: 'financeInvoices.import_invoice_return',
                    })}
                  </strong>
                )
              }

              if (lastUrl === 'transfer-in') {
                link = (
                  <strong>{intl.formatMessage({ id: 'financeWareHouses.transfer_in' })}</strong>
                )
              } else if (/warehouses\/[0-9]+\/transfer-in\/[0-9]+$/.test(match.url)) {
                link = ''
              }

              if (lastUrl === 'transfer-out') {
                link = (
                  <strong>{intl.formatMessage({ id: 'financeWareHouses.transfer_out' })}</strong>
                )
              } else if (/warehouses\/[0-9]+\/transfer-out\/[0-9]+$/.test(match.url)) {
                link = ''
              }

              if (lastUrl === 'add') {
                link = <strong>{intl.formatMessage({ id: 'breadcrumb.add' })}</strong>
              }

              if (splitUrl[splitUrl.length - 2] === 'add') {
                link = ''
              }

              if (lastUrl === 'edit') {
                link = <strong>{intl.formatMessage({ id: 'breadcrumb.edit' })}</strong>
              }

              if (lastUrl === 'detail' && index === breadcrumbs.length - 1) {
                link = <strong>{intl.formatMessage({ id: 'breadcrumb.detail' })}</strong>
              }

              if (lastUrl === 'email-template') {
                link = <strong>{menu.email_template}</strong>
              }

              if (lastUrl === 'due-reminder') {
                link = <strong>{menu.due_reminder}</strong>
              }

              if (lastUrl === 'taxes') {
                link = <strong>{menu.taxes}</strong>
              }

              if (lastUrl === 'unit') {
                link = <strong>{menu.units}</strong>
              }

              if (lastUrl === 'shipping-company' && index === breadcrumbs.length - 1) {
                link = <strong>{menu.shipping_comps}</strong>
              }

              if (lastUrl === 'expenses' && index === breadcrumbs.length - 1) {
                link = <strong>{menu.expenses}</strong>
              }

              if (lastUrl === 'products' && index === breadcrumbs.length - 1) {
                link = <strong>{menu.products}</strong>
              }

              if (
                index === breadcrumbs.length - 1 &&
                breadcrumbs[breadcrumbs.length - 1].match.path === '/products/access/:id'
              ) {
                link = <strong>{intl.formatMessage({ id: 'financeWareHouses.access' })}</strong>
              }

              if (lastUrl === 'price-rule') {
                if (index === breadcrumbs.length - 1) {
                  link = <strong>{intl.formatMessage({ id: 'priceRule.price_rule' })}</strong>
                } else {
                  link = (
                    <Link key={match.url} to={match.url} className="text-muted">
                      {intl.formatMessage({ id: 'priceRule.price_rule' })}
                    </Link>
                  )
                }
              }

              if (lastUrl === 'import-package') {
                if (index === breadcrumbs.length - 1) {
                  link = (
                    <strong>
                      {`${intl.formatMessage({ id: 'button.import' })} ${intl.formatMessage({
                        id: 'financeProduct.package',
                      })}`}
                    </strong>
                  )
                } else {
                  link = (
                    <Link key={match.url} to={match.url} className="text-muted">
                      {`${intl.formatMessage({ id: 'button.import' })} ${intl.formatMessage({
                        id: 'financeProduct.package',
                      })}`}
                    </Link>
                  )
                }
              }

              if (lastUrl === 'import-manufacture') {
                if (index === breadcrumbs.length - 1) {
                  link = (
                    <strong>
                      {`${intl.formatMessage({ id: 'button.import' })} ${intl.formatMessage({
                        id: 'financeProduct.manufacture',
                      })}`}
                    </strong>
                  )
                } else {
                  link = (
                    <Link key={match.url} to={match.url} className="text-muted">
                      {`${intl.formatMessage({ id: 'button.import' })} ${intl.formatMessage({
                        id: 'financeProduct.manufacture',
                      })}`}
                    </Link>
                  )
                }
              }

              if (lastUrl === 'import-conversion') {
                if (index === breadcrumbs.length - 1) {
                  link = (
                    <strong>
                      {`${intl.formatMessage({ id: 'button.import' })} ${intl.formatMessage({
                        id: 'financeProduct.conversion',
                      })}`}
                    </strong>
                  )
                } else {
                  link = (
                    <Link key={match.url} to={match.url} className="text-muted">
                      {`${intl.formatMessage({ id: 'button.import' })} ${intl.formatMessage({
                        id: 'financeProduct.conversion',
                      })}`}
                    </Link>
                  )
                }
              }

              if (lastUrl === 'conversions' && index === breadcrumbs.length - 1) {
                link = (
                  <strong>{intl.formatMessage({ id: 'financeProduct.product_conversion' })}</strong>
                )
              }

              if (lastUrl === 'finance-approval') {
                link = <strong>{menu?.approval}</strong>
              }

              if (lastUrl === 'approval-setting') {
                link = <strong>{menu?.finance_approval_setting}</strong>
              }

              if (lastUrl === 'tags' && index === breadcrumbs.length - 1) {
                link = <strong>{menu.tags}</strong>
              }

              if (match.url.includes('/settings/tags/access')) {
                link = <strong>{intl.formatMessage({ id: 'financeWareHouses.access' })}</strong>
              }

              if (lastUrl === 'contacts' && index === breadcrumbs.length - 1) {
                link = <strong>{menu.contacts}</strong>
              }

              if (lastUrl === 'import-accounts-payable' && index === breadcrumbs.length - 1) {
                link = (
                  <strong>
                    {intl.formatMessage(
                      { id: 'financeContacts.import_contact_transactions_with_title' },
                      {
                        credit: _term.payable,
                        debit: _term.receivable,
                      },
                    )}
                  </strong>
                )
              }

              if (lastUrl === 'accounts' && index === breadcrumbs.length - 1) {
                link = <strong>{menu.accounts}</strong>
              }

              if (lastUrl === 'warehouses' && index === breadcrumbs.length - 1) {
                link = <strong>{menu.inventory}</strong>
              }

              if (lastUrl === 'company') {
                link = <strong>{menu.company}</strong>
              }

              if (lastUrl === 'payments') {
                link = <strong>{intl.formatMessage({ id: 'payments.payment_method' })}</strong>
              }

              if (lastUrl === 'custom-column') {
                link = <strong>{menu.custom_columns}</strong>
              }

              if (lastUrl === 'terms') {
                link = <strong>{menu.terms}</strong>
              }

              if (lastUrl === 'business-flow') {
                link = <strong>{menu.businessflow}</strong>
              }

              if (lastUrl === 'customer-birthday') {
                link = <strong>{menu.customer_birthday}</strong>
              }

              if (lastUrl === 'automatic-sequencing') {
                link = <strong>{menu.automatic_sequencing}</strong>
              }

              if (lastUrl === 'users' && match.url !== '/pos/users') {
                link = <strong>{menu.users}</strong>
              }

              if (lastUrl === 'roles' && index === breadcrumbs.length - 1) {
                link = <strong>{menu.roles}</strong>
              }

              if (lastUrl === 'profile') {
                link = <strong>{menu.my_profile}</strong>
              }

              if (lastUrl === 'consolidation') {
                link = <strong>{menu.consolidation}</strong>
              }

              if (match.url === '/setup') {
                if (index === breadcrumbs.length - 1) {
                  link = <strong>{menu.setup}</strong>
                } else {
                  link = (
                    <Link key={match.url} to={match.url} className="text-muted">
                      {menu.setup}
                    </Link>
                  )
                }
              }

              if (lastUrl === 'clear-demo-data') {
                link = <strong>{intl.formatMessage({ id: 'setup.clear_demo_data' })}</strong>
              }

              if (lastUrl === 'add-room-types') {
                link = (
                  <strong>
                    {intl.formatMessage({ id: 'setup.setup_add_room_type_and_rooms' })}
                  </strong>
                )
              }

              if (lastUrl === 'finish') {
                link = <strong>{intl.formatMessage({ id: 'setup.finish' })}</strong>
              }

              if (lastUrl === 'fixed-assets' && index === breadcrumbs.length - 1) {
                link = <strong>{menu.fixed_assets}</strong>
              }

              if (lastUrl === 'lock-date' && index === breadcrumbs.length - 1) {
                link = <strong>{menu.lock_date}</strong>
              }

              if (lastUrl === 'warehouses' && index === breadcrumbs.length - 1) {
                link = <strong>{menu.inventory}</strong>
              }

              if (lastUrl === 'credit-memo') {
                link = <span className="text-muted">{_term.payable}</span>
              }

              if (lastUrl === 'debit-memo') {
                link = <span className="text-muted">{_term.receivable}</span>
              }

              if (lastUrl === 'importStatus') {
                if (index === breadcrumbs.length - 1) {
                  link = <strong>Import Status</strong>
                } else {
                  link = (
                    <Link key={match.url} to={match.url} className="text-muted">
                      Import Status
                    </Link>
                  )
                }
              }
              if (match.url === '/invoice-payment-credit-memo') {
                link = (
                  <span className="text-muted">
                    {intl.formatMessage({
                      id: 'invoicePaymentCreditMemo.invoicePaymentCreditMemo',
                    })}
                  </span>
                )
              }
              if (match.url === '/purchaseinvoice-payment-debit-memo') {
                link = (
                  <span className="text-muted">
                    {intl.formatMessage({
                      id: 'purchaseInvoicePaymentDebitMemo.purchaseInvoicePaymentDebitMemo',
                    })}
                  </span>
                )
              }
              if (match.url === '/unrealized-gain') {
                link = (
                  <span className="text-muted">
                    {intl.formatMessage({
                      id: 'unrealizedGain.unrealizedGain',
                    })}
                  </span>
                )
              }

              if (match.url === '/faqs') {
                if (index === breadcrumbs.length - 1) {
                  link = <strong>FAQ</strong>
                } else {
                  link = (
                    <Link key={match.url} to={match.url} className="text-muted">
                      FAQ
                    </Link>
                  )
                }
              }

              if (lastUrl === 'fees') {
                link = (
                  <strong>
                    {menu.fees ||
                      intl.formatMessage({
                        id: 'transactionFees.fees',
                      })}
                  </strong>
                )
              }

              if (match.url.includes('/settings/payment-connect/')) {
                link = (
                  <strong>
                    {paymentConnectProviders.find((provider) => provider.id === Number(lastUrl))
                      ?.name ?? 'Provider'}
                  </strong>
                )
              }

              if (lastUrl === 'access-per-contact') {
                link = (
                  <strong>
                    {intl.formatMessage({
                      id: 'financeWareHouses.per_contact_warehouse_access',
                    })}
                  </strong>
                )
              }

              if (match.url === '/reports/product-serial-stock-movement') {
                link = <strong>{reportmenu.inventory_product_serial_stock_movements}</strong>
              }

              if (match.url === '/reports/inventory-product-serial-stock') {
                link = <strong>{reportmenu.inventory_product_serial_stock}</strong>
              }

              if (match.url === '/reports/inventory-product-serial-expires-soon') {
                link = <strong>{reportmenu.inventory_product_serial_expires_soon}</strong>
              }

              if (match.url === '/reports/inventory-product-serial-expiry-age') {
                link = <strong>{reportmenu.inventory_product_serial_expiry_age}</strong>
              }

              if (match.url === '/reports/inventory-production-serial-number-history') {
                link = <strong>{reportmenu.inventory_product_serial_history}</strong>
              }

              if (match.url === '/purchases/purchaseinvoices/import-return') {
                const title = `${intl.formatMessage({ id: 'button.import' })} ${
                  menu.purchase_returns
                }`
                link = <strong>{title}</strong>
              }

              if (!link) {
                return null
              }

              return (
                <span key={match.url}>
                  {countBreadCrumb > 1 && index > 0 && (
                    <span className={`${styles.arrow} text-muted`} />
                  )}
                  {link}
                </span>
              )
            })}
          </div>
        )}
      </div>
    )
  }
}

export default withBreadcrumbs([
  ...routeConfig,
  // ...setupRoutes,
  ...financeBankRoutes,
])(injectIntl(Breadcrumbs))
