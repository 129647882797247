import axios from 'axios'
import {openNewTabBlobFile} from "../helper";

export const getFinanceJoinedPurchaseInvoice = (id) => {
  return axios.get(`/finance/joinedPurchaseInvoices/${id}`)
}

export const addFinanceJoinedPurchaseInvoice = (data) => {
  return axios.post(`/finance/joinedPurchaseInvoices`, data)
}

export const editFinanceJoinedPurchaseInvoice = (data) => {
  return axios.put(`/finance/joinedPurchaseInvoices/${data.id}`, data)
}

export const deleteFinanceJoinedPurchaseInvoice = (id) => {
  return axios.delete(`/finance/joinedPurchaseInvoices/${id}`)
}

export const deleteOnAddJoinFinancePurchaseInvoiceAttachment = (url) => {
  return axios.delete('/finance/joinedPurchaseInvoices/attachments', { data: { url } })
}

export const deleteOnEditFinanceJoinPurchaseInvoiceAttachment = (id, url) => {
  return axios.delete(`/finance/joinedPurchaseInvoices/${id}/attachments`, { data: { url } })
}

export const createPdfJoinedPurchaseInvoice = (id) => {
  return axios
    .get(`/finance/joinedPurchaseInvoices/${id}/print`, { responseType: 'blob' })
    .then((response) => {
      openNewTabBlobFile(response.data)
      return Promise.resolve(true)
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}
