import axios from 'axios'
import { openNewTabBlobFile } from 'utils/helper'

export const getFinanceJoinedInvoice = (id) => {
  return axios.get(`/finance/joinedSalesInvoices/${id}`)
}

export const addFinanceJoinedInvoice = (data) => {
  return axios.post(`/finance/joinedSalesInvoices`, data)
}

export const editFinanceJoinedInvoice = (data) => {
  return axios.put(`/finance/joinedSalesInvoices/${data.id}`, data)
}

export const deleteFinanceJoinedInvoice = (id) => {
  return axios.delete(`/finance/joinedSalesInvoices/${id}`)
}

export const deleteOnAddJoinFinanceInvoiceAttachment = (url) => {
  return axios.delete('/finance/joinedSalesInvoices/attachments', { data: { url } })
}

export const deleteOnEditJoinFinanceInvoiceAttachment = (id, url) => {
  return axios.delete(`/finance/joinedSalesInvoices/${id}/attachments`, { data: { url } })
}

export const createPdfJoinedSalesInvoice = (id) => {
  return axios
    .get(`/finance/joinedSalesInvoices/${id}/print`, { responseType: 'blob' })
    .then((response) => {
      openNewTabBlobFile(response.data)
      return Promise.resolve(true)
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}
