import { useMutation, useQuery } from 'react-query'

import {
  addFloor,
  createOutlet,
  createProductFavorites,
  deleteFloor,
  deleteOutlet,
  deleteProductFavorites,
  editFloor,
  editOutlet,
  editPosRole,
  getOutlets,
  getPosRoles,
  getPosUsers,
  getProductFavorites,
  moveProductFavorite,
  outletFloors,
} from 'utils/apis'

export const useProductFavorites = ({ options }) => {
  return useQuery(
    ['products-favorites'],
    async () => {
      const {
        data: { data },
      } = await getProductFavorites()
      return data
    },
    { ...options },
  )
}

export const useCreateProductFavorites = () => {
  return useMutation((payload) => createProductFavorites(payload))
}

export const useDeleteProductFavorites = () => {
  return useMutation(({ id }) => deleteProductFavorites({ id }))
}

export const useMoveProductFavorite = () => {
  return useMutation(({ id, payload }) => moveProductFavorite({ id, payload }))
}

export const useOutlets = ({ options, payload }) => {
  return useQuery(
    ['outlets', payload],
    async () => {
      const {
        data: { data },
      } = await getOutlets({ payload })
      return data
    },
    { ...options },
  )
}

export const useCreateOutlet = () => {
  return useMutation(({ payload }) => createOutlet({ payload }))
}

export const useEditOutlet = () => {
  return useMutation(({ id, payload }) => editOutlet({ id, payload }))
}

export const useDeleteOutlet = () => {
  return useMutation(({ id, payload }) => deleteOutlet({ id, payload }))
}

export const usePosUsers = ({ options = {}, payload } = {}) => {
  return useQuery(
    ['pos-users', payload],
    async () => {
      const {
        data: { data },
      } = await getPosUsers({ payload })
      return data
    },
    { ...options },
  )
}

export const useOutletFloors = ({ options, payload }) => {
  return useQuery(
    ['outletFloors', payload],
    async () => {
      const {
        data: { data },
      } = await outletFloors({ payload })
      return data
    },
    options,
  )
}

export const useAddFloorMutation = () => {
  return useMutation(({ id, payload }) => addFloor({ id, payload }))
}

export const useDeleteFloorMutation = () => {
  return useMutation(({ outletId, roomId }) => deleteFloor({ outletId, roomId }))
}

export const useEditFloorMutation = () => {
  return useMutation(({ outletId, roomId, body }) => editFloor({ outletId, roomId, body }))
}

export const usePosRoles = (options) => {
  return useQuery(
    ['pos-roles'],
    async () => {
      const {
        data: { data },
      } = await getPosRoles()
      return data
    },
    { ...options },
  )
}

export const useEditPosRole = () => {
  return useMutation(({ id, payload }) => editPosRole({ id, payload }))
}
