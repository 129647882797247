import axios from 'axios'
import { openNewTabBlobFile } from 'utils/helper'

export const getFinanceProformaInvoice = (id) => {
  return axios.get(`/finance/proformas/${id}`)
}

export const addFinanceProformaInvoice = (data) => {
  return axios.post(`/finance/proformas`, data)
}

export const editFinanceProformaInvoice = (data) => {
  return axios.put(`/finance/proformas/${data.id}`, data)
}

export const deleteFinanceProformaInvoice = (id) => {
  return axios.delete(`/finance/proformas/${id}`)
}

export const deleteOnAddFinanceProformaAttachment = (url) => {
  return axios.delete('/finance/proformas/attachments', { data: { url } })
}

export const deleteOnEditFinanceProformaAttachment = (id, url) => {
  return axios.delete(`/finance/proformas/${id}/attachments`, { data: { url } })
}

export const createPdfFinanceProforma = (id) => {
  return axios
    .get(`/finance/proformas/${id}/print`, { responseType: 'blob' })
    .then((response) => {
      openNewTabBlobFile(response.data)
      return Promise.resolve(true)
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}
