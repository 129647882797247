import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { message } from 'antd'
import { useIntl } from 'react-intl'

import { financeConfigSelector } from 'redux/selectors'
import { useNotificationProgress, useResponsive } from 'utils/hooks/index'

const useImport = ({ useUploadMutation, listPagePath, useExecuteMutation }) => {
  const [file, setFile] = useState(null)
  const [step, setStep] = useState(0)
  const [dataUploaded, setDataUploaded] = useState({
    url: '',
    rows: {
      valid: [],
      invalid: [],
    },
    valid: 0,
    invalid: 0,
    invalidDetail: [],
  })

  const intl = useIntl()

  const config = useSelector(financeConfigSelector)
  const dispatch = useDispatch()
  const onPush = (path) => dispatch(push(path))

  const { isMobileView } = useResponsive()

  const executeMutation = useExecuteMutation()
  const uploadMutation = useUploadMutation()

  const { onSetChannel, progress, onDisconnect, statusImported, onResetProgress } =
    useNotificationProgress()

  useEffect(() => {
    if (step === 2 && statusImported) {
      onDisconnect()
    }
  }, [onDisconnect, statusImported, onResetProgress, step])

  useEffect(() => {
    return () => {
      onResetProgress()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loading = uploadMutation.status === 'loading' || executeMutation.status === 'loading'

  const onSetAttachment = (url) => setFile(url)

  const onRemoveAttachment = () => setFile(null)

  const goToListPage = () => onPush(listPagePath)

  const onUpload = () => {
    if (!file) {
      message.error(intl.formatMessage({ id: 'financeInvoices.file_not_found' }))
      return
    }

    const formData = new FormData()
    formData.append('file', file)

    uploadMutation.mutate(formData, {
      onSuccess: (response) => {
        if (response.data.success) {
          const { result, url } = response.data.data
          setStep(1)
          setDataUploaded({
            url,
            rows: {
              valid: result.rows.valid || [],
              invalid: result.rows.invalid || [],
            },
            valid: result.valid,
            invalid: result.invalid,
            invalidDetail: result.invalidDetail || {},
          })
          message.success(response.data.message)
        } else {
          message.error(response.data.message)
        }
      },
      onError: () => {
        message.error(intl.formatMessage({ id: 'financeInvoices.error_upload_file' }))
      },
    })
  }

  const onExecuteImport = () => {
    executeMutation.mutate(
      { url: dataUploaded.url },
      {
        onSuccess: (response) => {
          if (response.data.success) {
            const { channel } = response.data.data
            setStep(2)
            onSetChannel(channel, 'App\\Events\\ImportProgress')
            message.success(response.data.message)
          } else {
            message.error(response.data.message)
          }
        },
        onError: () => {
          message.error(intl.formatMessage({ id: 'financeInvoices.error_cannot_import' }))
        },
      },
    )
  }

  const onNewImport = () => {
    onResetProgress()
    onDisconnect()
    setStep(0)
    setFile(null)
    setDataUploaded({
      url: '',
      rows: {
        valid: [],
        invalid: [],
      },
      valid: 0,
      invalid: 0,
      invalidDetail: [],
    })
  }

  return {
    loading,
    config,
    isMobileView,
    progress,
    statusImported,
    step,
    file,
    dataUploaded,
    goToListPage,
    onSetAttachment,
    onRemoveAttachment,
    onUpload,
    onExecuteImport,
    onNewImport,
  }
}

export default useImport
